import { motion } from "framer-motion";
import { useRouter } from "next/router";
import Head from "next/head";
import Custom404 from "./404";
import Container from "../components/container";
import PostBody from "../components/post-body";
import PageHeader from "../components/page-header";
import SectionSeparator from "../components/section-separator";
import Layout from "../components/layout";
import { getAllPagesWithSlug, getPage } from "../lib/api";
import PostTitle from "../components/post-title";

export default function Post({ page, pageHtml, preview, person }) {
  const router = useRouter();
  if (!router.isFallback && !page) {
    return <Custom404 />;
  }

  return (
    <Layout preview={preview}>
      <Container>
        {router.isFallback ? (
          <div role="status" className="max-w-4xl animate-pulse pb-16">
            <PostTitle>Loading…</PostTitle>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-slate-300 w-48 my-8"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-slate-300 max-w-[360px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-slate-300 mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-slate-300 max-w-[330px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-slate-300 max-w-[300px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-slate-300 max-w-[360px]"></div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <article>
              <Head>
                <title>{page.title} | Salazarpardo Design Portfolio</title>
              </Head>
              <PageHeader title={page.title} />
              <PostBody content={pageHtml} />
            </article>

            <SectionSeparator />
            <div className="banner mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
              <motion.div
                initial={{ opacity: 0, marginTop: 80 }}
                whileInView={{ opacity: 1, marginTop: 0 }}
              >
                <h3 className="text-2xl text-center">{person.contact}</h3>
                <p className="text-center">
                  <a
                    href="https://calendly.com/salazarpardo/intro"
                    target="_blank"
                    title="Calendly link"
                  >
                    Book a call
                  </a>
                </p>
              </motion.div>
            </div>
          </>
        )}
      </Container>
    </Layout>
  );
}

export async function getStaticProps({ params, preview = false }) {
  const data = await getPage(params.slug, preview);

  return {
    props: {
      preview,
      page: data?.page ?? null,
      pageHtml: data?.pageHtml ?? null,
      person: data?.person ?? null,
    },
    revalidate: 60,
  };
}

export async function getStaticPaths() {
  const allPages = await getAllPagesWithSlug();

  return {
    paths: allPages?.map(({ slug }) => `/${slug}`) ?? [],
    fallback: true,
  };
}
