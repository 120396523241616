import Layout from "../components/layout";
import Head from "next/head";
import Container from "../components/container";
import Link from "next/link";
export default function Custom404() {
  return (
    <Layout>
      <Head>
        <title>404 | Salazarpardo</title>
      </Head>
      <Container>
        <div className="min-h-screen flex flex-col justify-center">
          <h1 className=" font-bold text-7xl">
            <strong>404</strong>
          </h1>
          <h3 className="text-3xl mb-8">
            Sorry... we can't find what you are looking for.
          </h3>
          <Link href="/" className="btn">
            Go back home
          </Link>
        </div>
      </Container>
    </Layout>
  );
}
