import { motion } from "framer-motion";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import markdownStyles from "./markdown-styles.module.css";
import RichTextAsset from "./rich-text-asset";

export default function PostBody({ content, video }) {
  return (
    <>
      <motion.div
        className="project-content prose prose-xl lg:prose-2xl max-w-4xl mx-auto relative"
        dangerouslySetInnerHTML={{ __html: content }}
        initial={{ opacity: 0, top: 40 }}
        whileInView={{
          opacity: 1,
          top: 0,
        }}
        viewport={{ once: true }}
      ></motion.div>

      {video && video.url && (
        <motion.div
          className="project-video-wrapper relative"
          initial={{ opacity: 0, top: 40 }}
          whileInView={{
            opacity: 1,
            top: 0,
          }}
          viewport={{ once: true }}
        >
          <hr className="max-w-4xl mx-auto mb-16" />
          <video
            className="project-video max-w-full w-xs"
            autoPlay
            loop
            muted
            width="366"
            src={video.url}
          />
        </motion.div>
      )}
    </>
  );
}
