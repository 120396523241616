import { motion } from "framer-motion";
import Avatar from "./avatar";
import DateComponent from "./date";
import CoverImage from "./cover-image";
import PageTitle from "./page-title";

export default function PageHeader({ title, coverImage, date }) {
  return (
    <motion.div
      className="items-center pt-12 mb-8"
      initial={{ opacity: 0, marginTop: 40 }}
      whileInView={{ opacity: 1, marginTop: 0 }}
    >
      <PageTitle>{title}</PageTitle>
    </motion.div>
  );
}
